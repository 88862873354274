<template>
  <div class="popup__bg">
    <div class="popup col-md-5 col-xs-2">
    <div class="content">
        <div class="flex">
          <h4 class="popup__header">00000{{ orderId }}</h4>
          <span style="margin-right: 10px; font-size: 17px ;"><b-icon  icon="x-lg" type="submit" @click="showModal(orderId, index)"></b-icon></span>
        </div>
        <div class="popup__health-block flex">
            <div class="popup__health-item">
                <span class="popup__health-title">Пульс</span> 
                <span class="popup__health-icon">
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9997 5.33336H10.373C10.3266 5.32705 10.2795 5.32705 10.233 5.33336H10.1197C10.0848 5.35325 10.0514 5.37552 10.0197 5.40003C9.98255 5.42454 9.94693 5.45126 9.91301 5.48003C9.89075 5.50729 9.87069 5.53627 9.85301 5.5667C9.82246 5.60869 9.79569 5.65331 9.77301 5.70003L8.70634 8.64003L5.92634 1.1067C5.87963 0.977997 5.79442 0.8668 5.6823 0.788218C5.57019 0.709636 5.43659 0.66748 5.29967 0.66748C5.16276 0.66748 5.02916 0.709636 4.91704 0.788218C4.80493 0.8668 4.71972 0.977997 4.67301 1.1067L3.13301 5.33336H0.999674C0.822863 5.33336 0.653294 5.4036 0.52827 5.52863C0.403246 5.65365 0.333008 5.82322 0.333008 6.00003C0.333008 6.17684 0.403246 6.34641 0.52827 6.47144C0.653294 6.59646 0.822863 6.6667 0.999674 6.6667H3.61301H3.76634H3.86634C3.90423 6.64845 3.94001 6.6261 3.97301 6.60003C4.01013 6.57552 4.04575 6.5488 4.07967 6.52003L4.13967 6.43336C4.17145 6.39219 4.1983 6.34744 4.21967 6.30003L5.29967 3.28003L8.07301 10.8934C8.11959 11.0222 8.20474 11.1336 8.31687 11.2123C8.429 11.291 8.56267 11.3333 8.69967 11.3334C8.83668 11.3333 8.97035 11.291 9.08248 11.2123C9.19461 11.1336 9.27976 11.0222 9.32634 10.8934L10.8597 6.6667H12.9997C13.1765 6.6667 13.3461 6.59646 13.4711 6.47144C13.5961 6.34641 13.6663 6.17684 13.6663 6.00003C13.6663 5.82322 13.5961 5.65365 13.4711 5.52863C13.3461 5.4036 13.1765 5.33336 12.9997 5.33336Z" fill="#ACACAC"/>
                    </svg>
              </span>
                <p class="popup__health-value flex">
                <input v-model="items.pulse"  class="popup__input-value" type="text" :disabled="items.status != 1" @input="cleanInputPulse">
                  <span class="popup__health-icon">
                    <svg width="14" height="14"  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3664_15433)">
                        <path d="M6.18127 12.2491C6.57944 12.8168 7.42056 12.8168 7.81873 12.2491L9.39603 9.99994C9.86078 9.33724 9.38672 8.42576 8.5773 8.42576H5.4227C4.61328 8.42576 4.13922 9.33724 4.60397 9.99994L6.18127 12.2491ZM7.81873 1.75062C7.42056 1.18286 6.57944 1.18286 6.18127 1.75062L4.60397 3.99973C4.13922 4.66243 4.61328 5.57391 5.4227 5.57391L8.5773 5.57391C9.38672 5.57391 9.86078 4.66243 9.39603 3.99974L7.81873 1.75062Z" fill="#707070"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_3664_15433">
                          <rect width="14" height="14" fill="white"/>
                          </clipPath>
                        </defs>
                    </svg>
                </span>
                </p>
                <hr class="popup__health-hr">
            </div>
            <div class="popup__health-item">
                <span class="popup__health-title">Температура</span>
                <span class="popup__health-icon">
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.00033 4.3335V8.3335" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.94314 8.72402C5.46384 9.24472 5.46384 10.0889 4.94314 10.6096C4.42244 11.1303 3.57822 11.1303 3.05752 10.6096C2.53682 10.0889 2.53682 9.24472 3.05752 8.72402C3.57822 8.20332 4.42244 8.20332 4.94314 8.72402" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00033 7.01533V3C2.00033 1.89533 2.89566 1 4.00033 1C5.10499 1 6.00033 1.89533 6.00033 3V7.01533C6.80566 7.624 7.33366 8.58 7.33366 9.66667C7.33366 11.5073 5.84099 13 4.00033 13C2.15966 13 0.666992 11.5073 0.666992 9.66667C0.666992 8.58 1.19499 7.62333 2.00033 7.01533Z" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
                <p class="popup__health-value flex">
                  <input  v-model="items.temperature" type="text"  class="popup__input-value" :disabled="items.status != 1" @input="cleanInputTemperature"  >
                  <span class="popup__health-icon">
                    <svg width="14" height="14"  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3664_15433)">
                        <path d="M6.18127 12.2491C6.57944 12.8168 7.42056 12.8168 7.81873 12.2491L9.39603 9.99994C9.86078 9.33724 9.38672 8.42576 8.5773 8.42576H5.4227C4.61328 8.42576 4.13922 9.33724 4.60397 9.99994L6.18127 12.2491ZM7.81873 1.75062C7.42056 1.18286 6.57944 1.18286 6.18127 1.75062L4.60397 3.99973C4.13922 4.66243 4.61328 5.57391 5.4227 5.57391L8.5773 5.57391C9.38672 5.57391 9.86078 4.66243 9.39603 3.99974L7.81873 1.75062Z" fill="#707070"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3664_15433">
                        <rect width="14" height="14" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </span>
                </p>
                <hr class="popup__health-hr" />
            </div>
            <div class="popup__health-item">
                <span class="popup__health-title">Давление</span>
                <span class="popup__health-icon">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2426 2.75736C13.5858 5.10051 13.5858 8.89949 11.2426 11.2426C8.89948 13.5858 5.10049 13.5858 2.75736 11.2426C0.414213 8.89948 0.414213 5.10049 2.75736 2.75736C5.10051 0.414213 8.89949 0.414213 11.2426 2.75736" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.6086 11.6875C10.4906 11.3902 10.3073 11.1235 10.0726 10.9062C9.67596 10.5382 9.15729 10.3335 8.61596 10.3335C7.71329 10.3335 6.28663 10.3335 5.38396 10.3335C4.84263 10.3335 4.32396 10.5382 3.92663 10.9062C3.69196 11.1235 3.50929 11.3902 3.39062 11.6875" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.94314 6.05703C8.46384 6.57773 8.46384 7.42195 7.94314 7.94265C7.42244 8.46335 6.57822 8.46335 6.05752 7.94265C5.53682 7.42195 5.53682 6.57773 6.05752 6.05703C6.57822 5.53633 7.42244 5.53633 7.94314 6.05703" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.00033 1V2.33333" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.0003 1.80664L9.42029 2.80664" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.00036 1.80664L4.58036 2.80664" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1.80664 3.99988L2.80664 4.57988" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 6.99984H12" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 6.99984H1" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.19336 6.42L12.1934 4" stroke="#ACACAC" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <p class="popup__health-value flex">
                  <input v-model="items.pressure"  type="text" class="popup__input-value" :disabled="items.status != 1" @input="cleanInputPressure">
                  <span class="popup__health-icon">
                    <svg width="14" height="14"  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3664_15433)">
                        <path d="M6.18127 12.2491C6.57944 12.8168 7.42056 12.8168 7.81873 12.2491L9.39603 9.99994C9.86078 9.33724 9.38672 8.42576 8.5773 8.42576H5.4227C4.61328 8.42576 4.13922 9.33724 4.60397 9.99994L6.18127 12.2491ZM7.81873 1.75062C7.42056 1.18286 6.57944 1.18286 6.18127 1.75062L4.60397 3.99973C4.13922 4.66243 4.61328 5.57391 5.4227 5.57391L8.5773 5.57391C9.38672 5.57391 9.86078 4.66243 9.39603 3.99974L7.81873 1.75062Z" fill="#707070"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3664_15433">
                        <rect width="14" height="14" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </span>
                </p>
                <hr class="popup__health-hr" />
            </div>
            <div class="popup__health-item">
                <span class="popup__health-title">Алкотестер</span>
                <span class="popup__health-icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5203 2.4135C14.5977 2.31593 14.6462 2.19865 14.6603 2.07493C14.6744 1.95121 14.6536 1.826 14.6003 1.7135C14.5462 1.60005 14.4612 1.50418 14.3551 1.43695C14.2489 1.36971 14.1259 1.33385 14.0003 1.3335H2.00028C1.87462 1.33385 1.75162 1.36971 1.64546 1.43695C1.5393 1.50418 1.45431 1.60005 1.40028 1.7135C1.3469 1.826 1.32611 1.95121 1.34025 2.07493C1.35439 2.19865 1.4029 2.31593 1.48028 2.4135L7.33361 9.7335V13.3335H3.50028C3.32346 13.3335 3.1539 13.4037 3.02887 13.5288C2.90385 13.6538 2.83361 13.8234 2.83361 14.0002C2.83361 14.177 2.90385 14.3465 3.02887 14.4716C3.1539 14.5966 3.32346 14.6668 3.50028 14.6668H12.5003C12.6771 14.6668 12.8467 14.5966 12.9717 14.4716C13.0967 14.3465 13.1669 14.177 13.1669 14.0002C13.1669 13.8234 13.0967 13.6538 12.9717 13.5288C12.8467 13.4037 12.6771 13.3335 12.5003 13.3335H8.66694V9.7335L14.5203 2.4135ZM3.38694 2.66683H12.6136L11.0136 4.66683H5.01361L3.38694 2.66683ZM8.00028 8.4335L6.05361 6.00016H9.94694L8.00028 8.4335Z" fill="#ACACAC"/>
                  </svg>
              </span>
                <p class="popup__health-value flex">
                  <input v-model="items.breathalyzer" type="text" class="popup__input-value" :disabled="items.status != 1"  @input="cleanInputBreathalyzer"  >
                  <span class="popup__health-icon">
                    <svg width="14" height="14"  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3664_15433)">
                        <path d="M6.18127 12.2491C6.57944 12.8168 7.42056 12.8168 7.81873 12.2491L9.39603 9.99994C9.86078 9.33724 9.38672 8.42576 8.5773 8.42576H5.4227C4.61328 8.42576 4.13922 9.33724 4.60397 9.99994L6.18127 12.2491ZM7.81873 1.75062C7.42056 1.18286 6.57944 1.18286 6.18127 1.75062L4.60397 3.99973C4.13922 4.66243 4.61328 5.57391 5.4227 5.57391L8.5773 5.57391C9.38672 5.57391 9.86078 4.66243 9.39603 3.99974L7.81873 1.75062Z" fill="#707070"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3664_15433">
                        <rect width="14" height="14" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </span>
                </p>
                <hr class="popup__health-hr" />
            </div>
        </div>
        <b-form-checkbox v-model="complaints" type="checkbox"  class="popup__radio" :disabled="items.status != 1">Жалобы</b-form-checkbox>
        <b-form-input ref="input_complaints" v-model="items.driver_complaints"  class="popup__inputs" :disabled="!complaints"  @click="clearInput(1)"></b-form-input>
        <b-form-checkbox v-model="incapacitated" type="checkbox" class="popup__radio" :disabled="items.status != 1" >Недееспособен</b-form-checkbox>
        <b-form-input ref="input_incapacitated" v-model="items.driver_incapacitated" class="popup__inputs" :disabled="!incapacitated" @click="clearInput(2)"></b-form-input>
        <table class="popup__table">
            <tr class="popup__table-items">
              <td class="popup__table-item1">Водитель</td>
              <td class="popup__table-item2">{{ items.surname }} {{ items.name }}</td>
            </tr>
            <tr>
              <td class="popup__table-item1">ИИН водителя</td>
              <td class="popup__table-item2">{{ items.iin }}</td>
            </tr>
            <tr>
              <td class="popup__table-item1">Табельный номер</td>
              <td class="popup__table-item2">777777</td>
            </tr>
            <tr>
              <td class="popup__table-item1">Номер маршрута</td>
              <td class="popup__table-item2">{{ items.order_id }}</td>
            </tr>
            <tr>
              <td class="popup__table-item1">Время отъезда</td>
              <td class="popup__table-item2">{{ items.start_date }}</td>
            </tr>
            <tr>
              <td class="popup__table-item1">Время в пути</td>
              <td class="popup__table-item2">{{ items.driving_time ?? '- - - - - -' }}</td>
            </tr>
        </table>

        <div class="popup__buttons flex">
            <button :disabled="items.status != 1" class="header__btn right-item default-btn popup__button1" @click="refuse">
               Отказать
            </button>
            <button  :disabled="items.status != 1" class="header__btn right-item default-btn popup__button2" @click="approve">
               Одобрить
            </button>
        </div>
      </div>
    </div>
    <QrVue v-if="showQr" :qr-value='urlQr' @showQR = 'showQr = !showQr' ></QrVue>
  </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';

   export default {
      props: {
        orderId : {
          type : Number,
          default : 0
        },
        index : {
          type : Number,
          default : 0
        },
        driverId : {
          type : Number,
          default : 0
        }
      },
      data() {
        return {
          showQr: false,
          urlQr: '',
          orderStatus: 1,
          complaints: false,
          incapacitated: false,
          regEx : '123456789.-',
          items : {  
            pulse : '',
            temperature: '',
            pressure: '',
            breathalyzer: '',
            driver_complaints: 'Опишите жалобу',
            driver_incapacitated: 'Опишите причину',
          }
        }
      },
      components :{
        QrVue: () => ({
       component: import("./qr.vue"),
      }),
      },
      computed: mapGetters(['getRoutesDrivers']),
      methods: {
        ...mapActions(['updateDriverHealth']),
        async approve(){
          let encodeParam = encodeURIComponent(`medic_id=${this.$store.state.profileProducts.id}&order_id=${this.orderId}`)
          this.urlQr = `https://mgovsign.page.link/?link=https://api.sattiway.kz/api/web/mgovSign?${encodeParam}&apn=kz.mobile.mgov&isi=1476128386&ibi=kz.egov.mobile`     
          this.showQr = !this.showQr;   // показать qr-код

          if(this.orderStatus != 2){
            let checkOrderStatus = setInterval(() => {
              if(this.showQr == false){
                clearInterval(checkOrderStatus);
              }
              this.statusOrder(this.orderId)
              if(this.orderStatus == 2){
                  clearInterval(checkOrderStatus);
                  this.showQr = !this.showQr;
                  if(this.complaints == true){
                    this.items.complaints = this.items.driver_complaints;
                  }

                  if(this.incapacitated == true){
                    this.items.incapacitated = this.items.driver_incapacitated;
                  }

                  this.items.index = this.index
                  this.items.status = this.orderStatus     // ставим статус маршрута 2 == одобрен
                  this.updateDriverHealth(this.items);
                  this.$emit('showModal');
               }
            }, 2000); 
          }
        },
        async refuse(){
          if(this.complaints == true){
            this.items.complaints = this.items.driver_complaints;
          }

          if(this.incapacitated == true){
            this.items.incapacitated = this.items.driver_incapacitated;
          }
          this.items.index = this.index
          this.items.status = 3     // ставим статус маршрута 3 == отказ

          this.updateDriverHealth(this.items);
          this.$emit('showModal');
        },
        async statusOrder(order_id){
          let status = await this.$api
          .get(`/web/get-order-status?order_id=${order_id}`).then(function (response) {
              if(response.data[0] != undefined){
                return response.data[0].status;               
              }
            })
            .catch(function (error) {
                console.log(error);
            });
            status != undefined ? this.orderStatus = status : this.orderStatus = 1;
        },  
        showModal(order_id, index) {
          this.$emit('showModal', {
            orderId: order_id,
            index : index
          })
        },
        clearInput(val){
          if(val == 1){
            this.driver_complaints = '';
          }else if(val == 2){
            this.driver_incapacitated = '';
          }
        },
        cleanInputPulse(event) {
          this.items.pulse = event.target.value.replace(/[^0-9-]/g, ""); 
        },
        cleanInputTemperature(event) {
          this.items.temperature = event.target.value.replace(/[^0-9.]/g, ""); 
        },
        cleanInputPressure(event) {
          this.items.pressure = event.target.value.replace(/[^0-9-]/g, ""); 
        },
        cleanInputBreathalyzer(event) {
          this.items.breathalyzer = event.target.value.replace(/[^0-9.]/g, ""); 
        }
      },
      mounted() {
        this.items = this.getRoutesDrivers(this.index);
        if(this.items.complaints != ''){
          this.items.driver_complaints = this.items.complaints;
        }

        if(this.items.incapacitated != ''){
          this.items.driver_incapacitated = this.items.incapacitated;
        }
      },
      watch: {
        complaints: function (){
          if(this.complaints == 'false'){
            this.driver_complaints = 'Опишите жалобу';
          }
        },
        incapacitated: function (){
          if(this.complaints == false){
            this.driver_incapacitated = 'Опишите причину';
          }
        }
      }
   }
</script>

<style scoped>

.popup__bg{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.popup {
    background-color: #fff;
    width: 40%;
    margin: auto;
    margin-top: 5%;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 15px;
    z-index: 12;
    /* position: fixed;
    top: 25%;
    left: 30%; */
}
.content {
  margin: 0px 15px;
}
.popup__header {
  margin: 10px;
}

.popup__health-block {
  margin: auto;
  margin-top: 20px;
}
.popup__health-item {
    background: #f2f2f8;
    padding: 10px;
    border-radius: 8px;
    width: 24%;
}

.popup__health-title {
  font-size: 10px;
  line-height: 12px;
  color: #ACACAC;
}
.popup__health-icon svg{
  float: right;
  width: 20px;
  height: 20px;
}

.popup__health-value {
  font-size: 16px;
  margin-top: 10px;
  /* border: 1px solid black; */
}

.popup__input-value {
  margin: 0;
  padding: 0;
  width: 100%;
  background: #f2f2f8;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  color: #000000;
}

.popup__health-hr {
  margin: 0;
  margin-top: 5px;
}

.popup__radio {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.popup__inputs {
  margin-top: 10px;
  color: #ACACAC;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.popup__table {
  width: 100%;
  margin:auto;
  margin-top: 20px;
}

.popup__table-items {
  width: 100%;
}

.popup__table-item1 {
  width: 30%;
  color: #ACACAC;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  
  padding-top: 10px;
}

.popup__table-item2 {
  width: 70%;
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-top: 10px;
}

.popup__buttons {
  margin-top: 20px;
}

.popup__button1 {
  width: 48%;
  background: #F2F2F8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.popup__button2 {
  width: 48%;
  background: #E0E9FA;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366D6;
}
</style>